import React from 'react';
import useFetchData from '../hooks/fetchcategory.js';
import { Link } from 'react-router-dom';
function Owldemo1({ vendorid, setLoading }) {

  const { data } = useFetchData({ vendorid, setLoading });


    return (
      <div className='partners-bx mt-3'>
        <div className='container'>
          <div className="row title" style={{ marginBottom: "20px" }}>
            <div className="bheads mhead" data-aos="zoom-in">
               Category List ({data.length})
            </div>
          </div>
        </div>
        <div className='container'>
        {data.length > 0 && (
    <div className='productlist'>
      {data.map(item => (
         

            <div key={item.catid} className="titem item">
              <div className="mag-img">
             


              <Link  to={`/${item.url}`} className='icontitle'>    

              <img src={item.imgpath 
      ? `https://bulkify.in/photo/category/${item.imgpath}` 
      : `https://bulkify.in/photo/product/${item.imgpath1}`} className='img-fluid' alt="" title="" /> </Link>
               <Link to={`/${item.url}`} className='title'>  <b> {item.category}</b></Link>
              
              </div>
            </div>

           

          ))}
    </div>
  )}
          
        </div>
      </div>
    );

}

export default Owldemo1;
