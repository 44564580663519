import Header from './header';
import Footer from './footer';
import React, { useState, useEffect } from 'react';
import useFetchData from '../hooks/vendor/fetchbankdetail.js';
import { Link } from 'react-router-dom';
import { FaRupeeSign } from "react-icons/fa";
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';
function App() {

  const { sessionVid, setLoading } = useAuth();
  const vid = sessionVid;
  const { data } = useFetchData({vid, setLoading});
  const [values, setValues] = useState({
    bankdetail:"",
    qrcode:"",
  });

  useEffect(() => {
    if (data.length > 0) {
      setValues({
        ...values,
        bankdetail:data[0]?.bank,
        qrcode:data[0]?.qr_img,
      });
       
      
    }
  }, [data]);
  
 

  return (
    <div className="App">


<>
<Header />

<div className='bankdetails-bx mt-3'>
     
   
      <div class="container " > 

             <div class="bheads mhead" > Bank Details </div>


            <div className="bank-details-container mt-3 p-0" id="login">
            <div className="bank-details-card login-container" >
                <div className="bank-details-info input-group">
                    {values.bankdetail}
                </div>
             
            </div>

            <div className="bank-details-card login-container" >
             
                <div className="bank-details-info input-group">
                <label htmlFor="image">OR code Image</label>
                  {values.qrcode && (
                    <div className="current-image">
                      <img src={`https://bulkify.in/photo/category/${values.qrcode}`} alt="" style={{ width: '150px' }} />
                    </div>
                  )}
                
                </div>
            </div>
        </div>

             
              </div>


 

      </div>

  <Footer/>






</>






    </div>
  );
}

export default App;
