import Header from './header';
import Footer from './footer';
import React, { useState, useEffect } from 'react';
import useFetchData from '../hooks/vendor/fetchbankdetail.js';
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';
function App() {
  const [showMessage, setShowMessage] = useState(false);
  const { vendorSessionId,setLoading } = useAuth();
  const vid = vendorSessionId;
  const { data } = useFetchData({vid, setLoading});
  const [values, setValues] = useState({
    bankdetail:"",
    qrcode:"",
  });
  const [newImage, setNewImage] = useState(null);
  useEffect(() => {
    if (data.length > 0) {
      setValues({
        ...values,
        bankdetail:data[0]?.bank,
        qrcode:data[0]?.qr_img,
      });
       
      
    }
  }, [data]);
  
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => clearTimeout(timer); // Clean up the timer on unmount or before next effect.
    }
  }, [showMessage]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    
    if (name === 'image') {
      // If it's an image upload, we set the new image file
      setNewImage(files[0]);
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
      const form = e.target;
      const formData = new FormData(form);
    formData.append('vid', vendorSessionId);
    formData.append('formtype', 'updateBankdetail');
    if (newImage) {
      formData.append('image_edit', newImage); // Add new image file if it was uploaded
    }
    //formData.append('email', email);
    // profile_image.forEach((file, index) => {
    //   formData.append(`profile_image[${index}]`, file);
    // });


      try {
          

          const response = await axios.post('https://bulkify.in/api/vendor/update-bankdetails.php', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          

          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item

            if (dataItem.Massage === "Successfully Update") {
              setShowMessage(true);
              setLoading(false);
                //setMessage(dataItem.Massage);

                setTimeout(() => {
                 // navigate('/vendor/category-list', { state: { data: dataItem } });
        }, 1500);
            } else {
                console.log('Massage is not Done:', dataItem.Massage);
                //setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    
  };


  return (
    <div className="App">


<>
<Header />
<div>
      {showMessage && <div className='successpro'>Successfully Update.</div>}
    </div>
<div className='bankdetails-bx mt-3' >
     
   
      <div className="container " > 

             <div className="bheads mhead" > Bank Details </div>


            <div className="bank-details-container mt-3 p-0" id="login">
            <div className="bank-details-card login-container p-0" >
            <form onSubmit={handleSubmit} action="" method="post" encType="multipart/form-data" >
                <div className="bank-details-info input-group">
                    <textarea 
                    type="text"
                    id="bankdetail"
                    rows='10'
                    name="bankdetail"
                    onChange={handleChange}
                    value={values.bankdetail}
                  >{values.bankdetail}</textarea>
                </div>
                <div className="bank-details-info input-group">
                <label htmlFor="image">OR code Image</label>
                  {values.qrcode && (
                    <div className="current-image">
                      <img src={`https://www.pakwaancandlelightdinner.com/new_app/photo/category/${values.qrcode}`} alt="" style={{ width: '150px' }} />
                    </div>
                  )}
                  <input
                    type="file"
                    id="image"
                    name="image"
                    onChange={handleChange}
                    
                  />
                </div>

                <button type="submit" className="btn btn1">Update</button>
                </form>
            </div>
        </div>

             
              </div>


 

      </div>

  <Footer/>






</>






    </div>
  );
}

export default App;
