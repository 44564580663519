import Header from './header';
import Footer from './footer';
import React, { useState, useEffect } from 'react';
import useFetchData from '../hooks/vendor/fetchcontactdetail.js';
import { useAuth } from '../hooks/AuthContext';
import axios from 'axios';
function App() {
  const [showMessage, setShowMessage] = useState(false);
  const { vendorSessionId, setLoading } = useAuth();
  const vid = vendorSessionId;
  const { data } = useFetchData({vid, setLoading});
  const [values, setValues] = useState({
    contactdetail:"",
    whatsapp:"",
  });

  useEffect(() => {
    if (data.length > 0) {
      setValues({
        ...values,
        contactdetail:data[0]?.contact,
        whatsapp:data[0]?.whatsapp,
      });
       
      
    }
  }, [data]);
  
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => clearTimeout(timer); // Clean up the timer on unmount or before next effect.
    }
  }, [showMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
      const form = e.target;
      const formData = new FormData(form);
    formData.append('vid', vendorSessionId);
    formData.append('formtype', 'updateContactdetail');
    //formData.append('email', email);
    // profile_image.forEach((file, index) => {
    //   formData.append(`profile_image[${index}]`, file);
    // });


      try {
          

          const response = await axios.post('https://bulkify.in/api/vendor/update-contactdetails.php', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          

          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item

            if (dataItem.Massage === "Successfully Update") {
                //setMessage(dataItem.Massage);
                setLoading(false);
                setShowMessage(true);
                setTimeout(() => {
                 // navigate('/vendor/category-list', { state: { data: dataItem } });
        }, 1500);
            } else {
                console.log('Massage is not Done:', dataItem.Massage);
                //setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    
  };


  return (
    <div className="App">


<>
<Header />
<div>
      {showMessage && <div className='successpro'>Successfully Update.</div>}
    </div>
<div className='contactdetails-bx mt-3' >
     
   
      <div className="container " > 

             <div className="bheads mhead" > Info & contact details </div>


            <div className="bank-details-container mt-3 p-0" id="login">
            <div className="bank-details-card login-container p-0" >
            <form onSubmit={handleSubmit} action="" method="post" encType="multipart/form-data" >
                <div className="bank-details-info input-group">
                    <textarea 
                    type="text"
                    id="contactdetail"
                    rows='10'
                    name="contactdetail"
                    onChange={handleChange}
                    value={values.contactdetail}
                  >{values.contactdetail}</textarea>
                </div>
                <div className="bank-details-info input-group">
                  <label>Whatsapp Number</label>
                    <input 
                    type="text"
                    id="whatsapp"
                    name="whatsapp"
                    onChange={handleChange}
                    value={values.whatsapp}
                  />
                </div>

                <button type="submit" className="btn btn1">Update</button>
                </form>
            </div>
        </div>

             
              </div>


 

      </div>

  <Footer/>






</>






    </div>
  );
}

export default App;
