
import React, { useEffect, useState } from 'react';
import './assets/css/style.css';
import './assets/scss/main.scss';
import { useAuth  } from './hooks/AuthContext';
import Homepage from './components/homepage';
import PrivacyPolicy from './components/privacypolicy';
import Cart from './components/cart';
import UserLogin  from './components/userlogin';
import UserProfile  from './components/profile';
import ProductList from './components/productlist';
import Previous_orders from './components/previous_orders';
import OrderDetails from './components/orderdetail';
import Bankdetails from './components/bankdetails';
import Contact_detail from './components/contact_detail';
import Homepage2 from './vendor/homepage';
import VendorRegister from './vendor/register';
import VendorProductList from './vendor/productlist';
import VendorCategoryList from './vendor/categorylist';
import VendorUserList from './vendor/userlist';
import VendorAddUser from './vendor/adduser';
import EditUser from './vendor/edituser';
import VendorOrderList from './vendor/orderlist';
import VendorOrderDetail from './vendor/orderdetail';
import AddCategory from './vendor/addcategory';
import EditCategory from './vendor/editcategory';
import AddProduct from './vendor/addproduct';
import EditProduct from './vendor/editproduct';
import VendorProfile from './vendor/profile';
import VendorBankDetail from './vendor/bankdetail';
import VendorContactDetail from './vendor/contact_detail';
import VendorThanks from './vendor/thanks';
import Thanks from './components/thanks';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import UserNotFound from './components/NotFound'; // User 404 page
import VendorNotFound from './vendor/NotFound'; // Vendor 404 page

function Main() {
 
  const { sessionId, sessionVid, vendorSessionId } = useAuth();
 
  const [newcat, setNewcat] = useState(false);
  const [newprod, setNewprod] = useState(false);
  const [newusr, setNewusr] = useState(false);

  const [cids, setCids] = useState([]);
  const [urls, setUrls] = useState([]);

  const [vcids, setVcids] = useState([]);
  const [vurls, setVurls] = useState([]);

  const [pids, setPids] = useState([]);
  const [uids, setUids] = useState([]);

  const [uuids, setUuids] = useState([]);
  const [uuuids, setUuuids] = useState([]);

  useEffect(() => {
    if (sessionId && sessionVid) {
      fetch(`https://bulkify.in/api/get-category-url-name.php?vid=${sessionVid}`)
        .then(response => response.json())
        .then(data => {
          setCids(data.ids); 
          setUrls(data.urls); 
          setNewcat(false);
          console.log(data.urls);
        })
        .catch(error => console.error('Error fetching User Category Urls:', error));
    }
  }, [sessionId, sessionVid, newcat]); 


  useEffect(() => {
    if (sessionId && sessionVid) {
    fetch(`https://bulkify.in/api/vendor/get-order-id.php?userid=${sessionId}`)
      .then(response => response.json())
      .then(data => {
        setUuuids(data.ids); 
        console.log(data.ids);
      })
      .catch(error => console.error('Error fetching User Order Ids:', error));
    }
  }, [sessionId,sessionVid]);


 useEffect(() => {
    if (vendorSessionId) {
      fetch(`https://bulkify.in/api/get-category-url-name.php?vid=${vendorSessionId}`)
        .then(response => response.json())
        .then(data => {
            setVcids(data.ids); 
             setVurls(data.urls); 
             console.log(data.urls);
             setNewcat(false);
        })
        .catch(error => console.error('Error fetching Vendor Category Urls:', error));
    }
  }, [vendorSessionId, newcat]); 


  useEffect(() => {
    if (vendorSessionId) {
    fetch(`https://bulkify.in/api/vendor/get-product-id.php?vid=${vendorSessionId}`)
      .then(response => response.json())
      .then(data => {
        setPids(data.ids); 
        setNewprod(false);
        console.log(data.ids);
      })
      .catch(error => console.error('Error fetching Vendor Product Ids:', error));
    }
  }, [vendorSessionId, newprod]);

 useEffect(() => {
    if (vendorSessionId) {
    fetch(`https://bulkify.in/api/vendor/get-user-id.php?vid=${vendorSessionId}`)
      .then(response => response.json())
      .then(data => {
        setUids(data.ids); 
        console.log(data.ids);
        setNewusr(false);
      })
      .catch(error => console.error('Error fetching Vendor User Ids:', error));
    }
  }, [vendorSessionId, newusr]);

useEffect(() => {
    if (vendorSessionId) {
    fetch(`https://bulkify.in/api/vendor/get-order-id.php?vid=${vendorSessionId}`)
      .then(response => response.json())
      .then(data => {
        setUuids(data.ids); 
        console.log(data.ids);
      })
      .catch(error => console.error('Error fetching Vendor Order Ids:', error));
    }
  }, [vendorSessionId]);



  return (
    <div className="App">


 {/* <BrowserRouter  basename="/new_app">  */}

<BrowserRouter> 
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/userlogin" element={<UserLogin />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/previous_orders" element={<Previous_orders />} />
            <Route path="/bankdetails" element={<Bankdetails />} />
            <Route path="/contact_detail" element={<Contact_detail />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="*" element={<UserNotFound  />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy  />} />
            {cids.map((id, index) => {
              const url = urls[index];
              return (
                <>
                
                <Route
                  key={`p_${id}`}
                  path={`/${url}`}  
                  element={<ProductList catid={id} />}  
                />
                </>
              );
            })}


            <Route path="/vendor" element={<Homepage2 />} />
            <Route path="/vendor/register" element={<VendorRegister />} />
            <Route path="/vendor/category-list" element={<VendorCategoryList />} />
            <Route path="/vendor/product-list" element={<VendorProductList />} />
            <Route path="/vendor/user-list" element={<VendorUserList />} />
            <Route path="/vendor/adduser" element={<VendorAddUser setNewusr={setNewusr}/>} />
            <Route path="/vendor/order-list" element={<VendorOrderList />} />
           
            <Route path="/vendor/addcategory" element={<AddCategory setNewcat={setNewcat} />} />
            <Route path="/vendor/addproduct" element={<AddProduct setNewprod={setNewprod} />} />
            <Route path="/vendor/profile" element={<VendorProfile />} />
            <Route path="/vendor/bankdetail" element={<VendorBankDetail />} />
            <Route path="/vendor/contactdetail" element={<VendorContactDetail />} />
            <Route path="/vendor/thanks" element={<VendorThanks />} />
            {uuuids.map((id, index) => {
              return (
                <Route
                  key={`o_${id}`}
                  path={`/order-detail/${id}`}  // Adjust path structure as per your requirement
                  element={<OrderDetails orderid={id} />}  // Adjust props as per your requirement
                />
              );
            })}

            {vcids.map((id, index) => {
              const vurl = vurls[index];
              return (
                <>
                
                <Route
                  key={`vp_${id}`}
                  path={`vendor/${vurl}`}  
                  element={<VendorProductList ccatid={id} />}  
                />
                <Route
                  key={`ec_${id}`}
                  path={`/vendor/editcategory/${id}`}  
                  element={<EditCategory catid={id} setNewcat={setNewcat} />}  
                />
                </>
              );
            })}


            {pids.map((id, index) => {
              return (
                <Route
                  key={`ep_${id}`}
                  path={`/vendor/editproduct/${id}`}  // Adjust path structure as per your requirement
                  element={<EditProduct id={id} setNewprod={setNewprod}/>}  // Adjust props as per your requirement
                />
              );
            })}

            {uids.map((id, index) => {
              return (
                <Route
                  key={`eu_${id}`}
                  path={`/vendor/edituser/${id}`}  // Adjust path structure as per your requirement
                  element={<EditUser userId={id} setNewusr={setNewusr} />}  // Adjust props as per your requirement
                />
              );
            })}

             {uuids.map((id, index) => {
              return (
                <Route
                  key={`vo_${id}`}
                  path={`/vendor/order-detail/${id}`}  // Adjust path structure as per your requirement
                  element={<VendorOrderDetail orderid={id} />}  // Adjust props as per your requirement
                />
              );
            })}

            
            <Route path="vendor/*" element={<VendorNotFound  />} />
          </Routes>
    </BrowserRouter>






    </div>
  );
}

export default Main;
