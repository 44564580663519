import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Header from './header';
import Footer from './footer';
import { Link, useNavigate } from 'react-router-dom';
import cameraicon from '../assets/img/camera-icon.svg'; // Using import instead of require
import { useAuth } from '../hooks/AuthContext';
import useFetchData from '../hooks/vendor/fetchcategory.js';
import axios from 'axios';
import { MdDelete, MdEdit } from "react-icons/md";

const CategoryForm = ({setNewprod}) => {
    const { setLoading, vendorLogin, vendorSessionId, handleLogoutVendor, } = useAuth();
    const [product_title, setProduct_title] = useState('');
    const [category, setCategory] = useState('');
    const [code, setCode] = useState('');
    const [weight, setWeight] = useState('');
    const [full_description, setFull_description] = useState('');
    
    const [images, setImages] = useState([]);
    const [imagess, setImagess] = useState([]);
    const vid = vendorSessionId;
    const { data } = useFetchData({ vid, setLoading });

    const [fields, setFields] = useState([{ stprice: '', stqty: '', stdiscount: '', unit: '1' }]);
    const handleAddField = () => {
        setFields([...fields, { stprice: '', stqty: '', stdiscount: '', unit: '1'}]);
      };

      const handleChange = (index, event) => {
        const values = [...fields];
        values[index][event.target.name] = event.target.value;
        setFields(values);
      };
      const handleRemoveField = (index) => {
        const values = [...fields];
        values.splice(index, 1); // Remove the specific field set
        setFields(values);
      };
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'product_title') setProduct_title(value);
        if (name === 'code') setCode(value);
        if (name === 'weight') setWeight(value);
        if (name === 'full_description') setFull_description(value);
      };
    const handleFileChange = (event) => {
        const files = event.target.files;
        const newImagesArray = Array.from(files).map((file) =>
          URL.createObjectURL(file)
        );
        const newFilesArray = Array.from(files);
        if (images.length + newImagesArray.length > 10) {
          alert("You can only upload a maximum of 10 images.");
          return;
        }
    
        setImages((prevImages) => [...prevImages, ...newImagesArray]);
        setImagess((prevImagess) => [...prevImagess, ...newFilesArray]);
      };
    
      // Handle reordering of images
      const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedImages = Array.from(images);
        const [movedImage] = reorderedImages.splice(result.source.index, 1);
        reorderedImages.splice(result.destination.index, 0, movedImage);
    
        setImages(reorderedImages);
      };
    
      // Remove image from the list
      const removeImage = (index,e) => {
        e.preventDefault();
        const newImages = images.filter((_, i) => i !== index);
        const newImagess = imagess.filter((_, i) => i !== index);
        setImages(newImages);
        setImagess(newImagess);
      };
    
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const validateForm = () => {
    const newErrors = {};
    
    if (!category) newErrors.category = 'Category Name is required';
    if (images.length === 0) newErrors.images = 'Photos is required';
    if (!product_title) newErrors.product_title = 'Product Title is required';
    /* for (let i = 0; i < fields.length; i++) {
        const { stprice, stqty, unit } = fields[i];
       
        if (i==0 && !stprice) {
            newErrors.price_inventory = 'Product Price is required';
        }else if(i>0 && !stprice && !stqty) {
            newErrors.price_inventory = 'Product Price And Max Qty is required';
        }else if (unit==2 && !weight){
            newErrors.weight = 'Product Weight is required';
        }
    } */
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }else{
      setLoading(true);
      const form = e.target;
      const formData = new FormData(form);
    formData.append('vid', vendorSessionId);
    //formData.append('email', email);
    fields.forEach((field, index) => {
        formData.append(`stprice[${index}]`, field.stprice);
        formData.append(`stqty[${index}]`, field.stqty);
        formData.append(`stdiscount[${index}]`, field.stdiscount);
        formData.append(`unit[${index}]`, field.unit);
      });    
    imagess.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });

      try {
          

          const response = await axios.post('https://bulkify.in/api/vendor/add-product.php', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });

          const responseData = response.data;
          

          if (responseData.data && Array.isArray(responseData.data)) {
            const dataItem = responseData.data[0]; // Assuming you want to check the first item

            if (dataItem.Massage === "Done") {
                //setMessage(dataItem.Massage);
                setNewprod(true);
                setTimeout(() => {
                  navigate('/vendor/product-list', { state: { data: dataItem } });
                  setLoading(false);
        }, 500);
            } else {
                console.log('Massage is not Done:', dataItem.Massage);
                //setMessage(dataItem.Massage);
            }
        } else {
            console.error('Unexpected response data format:', responseData);
        }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              alert('Page not found');
          } else {
              console.error('An error occurred:', error);
          }
      } finally {
        //setLoading(false);
      }
    }
  };

  return (
    <>
      <Header />
      <div id="login">
        <div className="login-container">
          <h1>Add New Product</h1>
          <form onSubmit={handleSubmit} action="" method="post" encType="multipart/form-data" >
          <div className="input-group">
              <label htmlFor="cname">Photos</label>
              

              {images.length > 0 ? (
                <>
       
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="images" direction="horizontal">
            {(provided) => (
                <>
              <div
                className="image-preview-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
              >
                {images.map((image, index) => (
                  <Draggable key={`image-${index}`} draggableId={`image-${index}`} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          display: "inline-block",
                          position: "relative",
                          width: "31.85%",
                          
                        }}
                      >
                        <div className='image-thumb-preview'>
                        <img
                          src={image}
                          alt={`preview-${index}`}
                          className='ec-image-preview'
                        />
                        <button
                          className='removeimg'
                          onClick={(e) => removeImage(index, e)}
                        >
                          <MdDelete />
                        </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              
           
          
        
        <div className='thumb-edit'>
        <div className='editinnr'>
        
        <input
          type="file"
          id="photos"
          multiple
          accept="image/*"
          onChange={handleFileChange}
          disabled={images.length >= 10}
          
        />
        <label htmlFor="productimg">
        <span> 
          <img src={cameraicon} className="svg_img header_svg" alt="edit" /> 		<small> add a photo </small>
      </span>
      </label>
      </div>
      </div>
      </div>
      </>
       )}
      </Droppable>
      </DragDropContext>
        {errors.images && <p className="error">{errors.images}</p>}
        </>
      ):(
        <>
        <div className='thumb-edit'>
              <div className='editinnr'>
              
              <input
                type="file"
                id="photos"
                multiple
                accept="image/*"
                onChange={handleFileChange}
                disabled={images.length >= 10}
                
              />
              <label htmlFor="productimg">
              <span> 
                <img src={cameraicon} className="svg_img header_svg" alt="edit" /> 		<small> add a photo </small>
            </span>
            </label>
            </div>
            </div>
              {errors.images && <p className="error">{errors.images}</p>}
              </>
      ) }
            </div>
            <div className="input-group">
              <label htmlFor="category">Select Categories</label>
              <select
                type="text"
                id="category"
                name="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                 <option value="">Select Category</option>
                 {data.length > 0 && (
                 data.map(item => (
                    <option key={item.catid} value={item.catid}>{item.category}</option>
                        ))
                    )}
                </select>

              {errors.category && <p className="error">{errors.category}</p>}
            </div>
             <div className="input-group">
              <label htmlFor="product_title">Product Title</label>
              <input
                type="text"
                id="product_title"
                name="product_title"
                value={product_title}
                onChange={handleInputChange}
                
              />
              {errors.product_title && <p className="error">{errors.product_title}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="code">SKU</label>
              <input
                type="text"
                id="code"
                name="code"
                value={code}
                onChange={handleInputChange}
                
              />
              {errors.code && <p className="error">{errors.code}</p>}
            </div>
            
            <div className="input-group">
              <label htmlFor="weight">Product Weight</label>
              <input
                type="text"
                id="weight"
                name="weight"
                value={weight}
                onChange={handleInputChange}
                
              />
              {errors.weight && <p className="error">{errors.weight}</p>}
            </div>
            
            
         
            <div className="input-group">
              <label htmlFor="full_description">Product Description</label>
              <textarea
                
                id="full_description"
                name="full_description"
                value={full_description}
                onChange={handleInputChange}
                
              ></textarea>
              {errors.full_description && <p className="error">{errors.full_description}</p>}
            </div>

           <div className="input-group">
              <label>Price and Inventory
              </label><br/>
              {fields.map((field, index) => (
        <div className='row input-group py-3' key={index} id='pricebox' style={{border: '1px solid #ababab'}}>
          <div className='col-6'>
            <label>Price</label>
            <input
              type="number"
              step="0.01"
              name="stprice"
              value={field.stprice}
              onChange={(e) => handleChange(index, e)}
            />
          </div>
          <div className='col-6'>
            <label>Max Qty</label>
            <input
              type="text"
              name="stqty"
              value={field.stqty}
              onChange={(e) => handleChange(index, e)}
            />
          </div>
          <div className='col-6'>
            <label>Discount %</label>
            <input
              type="text"
              name="stdiscount"
              value={field.stdiscount}
              onChange={(e) => handleChange(index, e)}
            />
          </div>
          <div className='col-6'>
            <label>Unit</label>
            <select
              name="unit"
              value={field.unit}
              onChange={(e) => handleChange(index, e)}
            >
              <option value="1">price per Piece</option>
              <option value="2">price per Gram</option>
            </select>
          </div>
          <div className='col-12'>
        {fields.length > 1 && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleRemoveField(index)}
          >
            Remove
          </button>
        )}
      </div>
        </div>
        
      ))}
             <div className='col-12'>
                    <button
                        type="button"
                        id="code"
                        className='btn btn1'
                        onClick={handleAddField}
                    >Add More</button>
                </div>   
                {errors.price_inventory && <p className="error">{errors.price_inventory}</p>}
              </div>
        
              
           <div className="input-group">
              <label htmlFor="full_description">Variants
              </label>
              <input
                type="button"
                value=' + Add options like size or color'
                
              />
             
             
            </div>

           
            <button type="submit" className="btn btn1">Submit</button>

          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryForm;
